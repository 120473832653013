import 'bootstrap/dist/css/bootstrap.css'
import 'ol/ol.css';
//import 'ol-layerswitcher/dist/ol-layerswitcher.css';
import 'ol-ext/dist/ol-ext.css';


import {Map, View} from 'ol';
import LayerTile from 'ol/layer/Tile';
import SourceOSM from 'ol/source/OSM';
import LayerImage from 'ol/layer/Image';
import ImageWMS from 'ol/source/ImageWMS';
import Projection from 'ol/proj/Projection';
import {Attribution, defaults as defaultControls} from 'ol/control';

//import LayerSwitcher from 'ol-layerswitcher';
import LayerSwitcher from 'ol-ext/control/LayerSwitcher';
import LayerGroup from 'ol/layer/Group';


//import { WindLayer } from 'ol-wind';

const projection = new Projection({
  code: 'EPSG:3857',
  units: 'm',
});

var attribution = new Attribution({
  collapsible: true,
});

const osm = new LayerTile({
  title: 'OpenStreetMap',
  source: new SourceOSM(),
  baseLayer: true
});

const project_extent = [990405.4771480667404830, 6107521.8328080121427774, 996749.5904812883818522, 6113064.7103385459631681];

// const windLayer = new WindLayer(res, {
//   forceRender: false,
//   windOptions: {
//     // colorScale: scale,
//     velocityScale: 1 / 20,
//     paths: 5000,
//     // eslint-disable-next-line no-unused-vars
//     colorScale: () => {
//       // console.log(m);
//       return '#ff473c';
//     },
//     width: 3,
//     // colorScale: scale,
//     generateParticleOption: false
//   },
//   // map: map,
//   // projection: 'EPSG:4326'
// });

const buildings = new LayerImage({
  title: 'Gebäude',
  extent: project_extent,
  displayInLayerSwitcher: false,
  visible: true,
  source: new ImageWMS({
    url: 'https://mapserver.mapwebbing.eu/cgi-bin/mapserv?map=/srv/projects/demo/demo.map',
    params: {'LAYERS': 'buildings'},
    projection: projection,
    ratio: 1,
    serverType: 'mapserver',
    attributions: ['© <a href="https://www.lgl-bw.de">Landesamt für Geoinformation und Landentwicklung Baden-Würtemberg (LGL)</a>']
  }),
});

const fg_sel = new LayerImage({
  title: 'selten',
  extent: project_extent,
  displayInLayerSwitcher: true,
  visible: true,
  baseLayer: true,
  source: new ImageWMS({
    url: 'https://mapserver.mapwebbing.eu/cgi-bin/mapserv?map=/srv/projects/demo/demo.map',
    params: {'LAYERS': 'fg_sel'},
    projection: projection,
    ratio: 1,
    serverType: 'mapserver',
    attributions: ['<a href="https://www.itr-gmbh.de/">ITR GmbH</a>']
  }),
});

const fr_sel = new LayerImage({
  title: 'selten',
  extent: project_extent,
  displayInLayerSwitcher: true,
  visible: true,
  baseLayer: true,
  source: new ImageWMS({
    url: 'https://mapserver.mapwebbing.eu/cgi-bin/mapserv?map=/srv/projects/demo/demo.map',
    params: {'LAYERS': 'fr_sel'},
    projection: projection,
    ratio: 1,
    serverType: 'mapserver',
    attributions: ['<a href="https://www.itr-gmbh.de/">ITR GmbH</a>']
  }),
});

const fg_aus = new LayerImage({
  title: 'außergewöhnlich',
  extent: project_extent,
  displayInLayerSwitcher: true,
  visible: false,
  baseLayer: true,
  source: new ImageWMS({
    url: 'https://mapserver.mapwebbing.eu/cgi-bin/mapserv?map=/srv/projects/demo/demo.map',
    params: {'LAYERS': 'fg_aus'},
    projection: projection,
    ratio: 1,
    serverType: 'mapserver',
    attributions: ['<a href="https://www.itr-gmbh.de/">ITR GmbH</a>']
  }),
});

const fr_aus = new LayerImage({
  title: 'außergewöhnlich',
  extent: project_extent,
  displayInLayerSwitcher: true,
  visible: false,
  baseLayer: true,
  source: new ImageWMS({
    url: 'https://mapserver.mapwebbing.eu/cgi-bin/mapserv?map=/srv/projects/demo/demo.map',
    params: {'LAYERS': 'fr_aus'},
    projection: projection,
    ratio: 1,
    serverType: 'mapserver',
    attributions: ['<a href="https://www.itr-gmbh.de/">ITR GmbH</a>']
  }),
});

const fg_ext = new LayerImage({
  title: 'extrem',
  extent: project_extent,
  displayInLayerSwitcher: true,
  visible: false,
  baseLayer: true,
  source: new ImageWMS({
    url: 'https://mapserver.mapwebbing.eu/cgi-bin/mapserv?map=/srv/projects/demo/demo.map',
    params: {'LAYERS': 'fg_ext'},
    projection: projection,
    ratio: 1,
    serverType: 'mapserver',
    attributions: ['<a href="https://www.itr-gmbh.de/">ITR GmbH</a>']
  }),
});

const fr_ext = new LayerImage({
  title: 'extrem',
  extent: project_extent,
  vdisplayInLayerSwitcher: true,
  visible: false,
  baseLayer: true,
  source: new ImageWMS({
    url: 'https://mapserver.mapwebbing.eu/cgi-bin/mapserv?map=/srv/projects/demo/demo.map',
    params: {'LAYERS': 'fr_ext'},
    projection: projection,
    ratio: 1,
    serverType: 'mapserver',
    attributions: ['<a href="https://www.itr-gmbh.de/">ITR GmbH</a>']
  }),
});

const ut_sel = new LayerImage({
  title: 'selten',
  extent: project_extent,
  displayInLayerSwitcher: true,
  baseLayer: true,
  source: new ImageWMS({
    url: 'https://mapserver.mapwebbing.eu/cgi-bin/mapserv?map=/srv/projects/demo/demo.map',
    params: {'LAYERS': 'ut_sel'},
    projection: projection,
    ratio: 1,
    serverType: 'mapserver',
    attributions: ['<a href="https://www.itr-gmbh.de/">ITR GmbH</a>']
  }),
});

const ut_aus = new LayerImage({
  title: 'außergewöhnlich',
  extent: project_extent,
  displayInLayerSwitcher: true,
  visible: false,
  baseLayer: true,
  source: new ImageWMS({
    url: 'https://mapserver.mapwebbing.eu/cgi-bin/mapserv?map=/srv/projects/demo/demo.map',
    params: {'LAYERS': 'ut_aus'},
    projection: projection,
    ratio: 1,
    serverType: 'mapserver',
    attributions: ['<a href="https://www.itr-gmbh.de/">ITR GmbH</a>']
  }),
});

const ut_ext = new LayerImage({
  title: 'extrem',
  extent: project_extent,
  displayInLayerSwitcher: true,
  visible: false,
  baseLayer: true,
  source: new ImageWMS({
    url: 'https://mapserver.mapwebbing.eu/cgi-bin/mapserv?map=/srv/projects/demo/demo.map',
    params: {'LAYERS': 'ut_ext'},
    projection: projection,
    ratio: 1,
    serverType: 'mapserver',
    attributions: ['<a href="https://www.itr-gmbh.de/">ITR GmbH</a>']
  }),
});

const overlays = [
  new LayerGroup({
    title: 'Überflutungstiefen',
    fold: 'open',
    openInLayerSwitcher: true,
    layers: [ut_ext, ut_aus, ut_sel]
  }),
  new LayerGroup({
    title: 'Fließgeschwindigkeiten',
    fold: 'open',
    openInLayerSwitcher: true,
    layers: [fg_ext, fg_aus, fg_sel]
  }),
  new LayerGroup({
    title: 'Fließrichtung',
    fold: 'open',
    openInLayerSwitcher: true,
    layers: [fr_ext, fr_aus, fr_sel]
  })
];

const basemaps = [
  osm,
  new LayerGroup({
    title: 'Überflutungstiefen',
    fold: 'open',
    openInLayerSwitcher: true,
    layers: [ut_ext, ut_aus, ut_sel]
  }),
  new LayerGroup({
    title: 'Fließgeschwindigkeiten',
    fold: 'open',
    visible: false,
    openInLayerSwitcher: true,
    layers: [fg_ext, fg_aus, fg_sel]
  }),
  new LayerGroup({
    title: 'Fließrichtung',
    fold: 'open',
    visible: false,
    openInLayerSwitcher: true,
    layers: [fr_ext, fr_aus, fr_sel]
  }),
  new LayerGroup({
    visible: true,
    displayInLayerSwitcher: false,
    layers: [buildings]
  })  
];


const map = new Map({
  target: 'map',
  layers: basemaps,
  controls: defaultControls({attribution: false}).extend([attribution]),
  view: new View({
    center: [993390.5,6110105.8],
    zoom: 15,
    projection: projection
  })
});

// const layerSwitcher = new LayerSwitcher({
//   activationMode: 'click',
//   startActive: true,
//   groupSelectStyle: 'none'
// });
const layerSwitcher = new LayerSwitcher({
  show_progress: false,
  trash: false,
  collapsed: false,
  reordering: false
});
map.addControl(layerSwitcher);